<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="미디어 속 태성" bg="/images/sub/visual/sv-media.jpg" />
        </template>
        <page-section tit="미디어 속 태성" titAdd="Media" class="page-section--media">
            <v-divider />
            <div class="media-area pt-30px pt-md-60px">
                <v-row align="center">
                    <v-col data-aos="fade-up" cols="12" md="5">
                        <tit>이혼전문변호사 최유나</tit>
                    </v-col>
                    <v-col data-aos="fade-up" cols="12" md="7">
                        <v-responsive max-width="1032" :aspect-ratio="1032 / 580">
                            <iframe :src="'https://www.youtube.com/embed/'+selectedVideo" class="w-100 h-100 d-block" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                        </v-responsive>
                    </v-col>
                </v-row>
                <v-divider class="mt-30px mt-md-60px mb-40px mb-md-80px" />
                <v-row data-aos="fade-up" class="my-md-n30px">
                    <v-col v-for="item in media" :key="item.title" cols="6" md="4" class="py-md-30px">
                        <v-card tile class="media-card v-card--none-active-bg" color="transparent" @click="changeVideoAndScroll(item)">
                            <div class="media-card__thumb w-100">
                                <v-img :src="item.image" max-width="464" :aspect-ratio="464 / 260" />
                                <btn-arrow-secondary color="white" class="media-card__btn" />
                            </div>
                            <txt class="txt--dark text-truncate mt-12px mt-md-24px">{{item.title}}</txt>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { media } from "@/assets/data/media.js";

import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import BtnArrowSecondary from "@/components/publish/parents/buttons/btn-arrow-secondary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        BtnArrowSecondary,

        Tit,
        Txt,
    },
    data() {
        return {
            media,
            selectedVideo: "-Z5pKRsHDPA",
        }
    },
    mounted() {},
    methods: {
        changeVideoAndScroll(item) {
            this.selectedVideo = item.video;
            document.querySelector('.media-area').scrollIntoView({ behavior: "smooth" });
        }
    }
};
</script>

<style lang="scss" scoped>
.page-section--media{
    background-image: url(/images/sub/media/media-bg.svg);
    background-size: auto 100%;
    background-position: left bottom;
}
.media-card{
    display: block;
    &__thumb{
        position: relative;
        overflow: hidden;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.7);
            opacity: 0;
            visibility: hidden;
            z-index: 1;
            transition: 0.2s ease-out;
        }
    }
    &__btn{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(1.4);
        z-index: 2;
        opacity: 0;
        visibility: hidden;
        transition: 0.2s ease-out;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .media-card{
        &:hover{
            .media-card__thumb::after{
                opacity: 1;
                visibility: visible;
                transition: 0.2s ease-out;
            }
            .media-card__btn{
                opacity: 1;
                visibility: visible;
                transform: translate(-50%, -50%) scale(1);
                transition: 0.2s 0.2s ease-out;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>
